<template>
  <div class="input">
    <input :type="passwordViewStatus?'text':type"
           v-model="text"
           @keypress="onInput"/>
    <div v-show="type === 'password'">
      <i class="iconfont icon-view"
         v-show="!passwordViewStatus"
         @click="passwordViewStatus = true"
      ></i>
      <i class="iconfont icon-pwd-hidden"
         v-show="passwordViewStatus"
         @click="passwordViewStatus = false"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    modelValue: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      passwordViewStatus: false,
      text: this.modelValue || ''
    }
  },
  methods: {
    onInput() {
      // // console.log(this.text)
      this.$emit('input', this.text)
    }
  }
}
</script>

<style scoped lang="less">
.input {
  border: 1px #d7d7d7 solid;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 5px;

  > input {
    flex: 1;
    border: 0;
    min-height: 30px;
    outline: none;
  }
}
</style>