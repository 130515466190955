<template>
  <div class="login">
    <Page>
      <div class="left">

      </div>
      <div class="form">
        <div>E-mail</div>
        <Input v-model="email"/>
        <div>Password*</div>
        <Input type="password"/>
        <div class="other">
          <CheckBox>Remember me</CheckBox>
          <div>Forgot password?</div>
        </div>
        <button>Sign in</button>
        <div class="line">
          <i></i>
          <div>or</div>
          <i></i>
        </div>
        <div class="link">
          <svg class="iconfont" aria-hidden="true">
            <use xlink:href="#icon-facebook"></use>
          </svg>
          <div>Sign up with Facebook</div>
        </div>
        <div class="link">
          <svg class="iconfont" aria-hidden="true">
            <use xlink:href="#icon-google"></use>
          </svg>
          <div>Sign up with Google</div>
        </div>
        <div class="sign_link">
          <div>Not registered yet?</div>
          <div>Sign up</div>
        </div>
      </div>
    </Page>
  </div>
</template>

<script>
import Page from "@/components/Page"
import Input from "@/components/Input"
import CheckBox from "@/components/CheckBox"

export default {
  name: "index",
  components: {CheckBox, Input, Page},
  data() {
    return {
      email: ''
    }
  }
}
</script>

<style scoped lang="less">
.login {
  padding: 20px 0 30px;
  user-select: none;

  > .page {
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    padding: 28px 10px;

    > .form {
      width: 400px;
      color: #a1a1a1;
      font-size: 18px;

      > * {
        margin-bottom: 15px;
      }

      > .other {
        display: flex;
        justify-content: space-between;
        font-size: 16px;

        > div {
          &:last-child {
            color: #0C5CA8;
          }
        }
      }

      > button {
        background-color: #0C5CA8;
        width: 100%;
        color: #fff;
        padding: 10px 0;
        border: 0;
        border-radius: 5px;
      }

      > .line {
        display: flex;
        align-items: center;
        justify-content: center;

        > i {
          flex: 1;
          border: 1px #c8c8c8 solid;
        }

        > div {
          width: 60px;
          text-align: center;
        }
      }

      > .link {
        display: flex;
        align-items: center;
        background-color: #ececec;
        border-radius: 5px;
        padding: 8px 0;
        font-size: 16px;

        > .iconfont {
          flex: 0.3;
          width: 25px;
          height: 25px;
        }

        > div {
          flex: 0.7;
        }
      }

      > .sign_link {
        display: flex;
        font-size: 16px;

        > div {
          &:last-child {
            color: #0C5CA8;
          }
        }
      }
    }
  }
}
</style>